I18n.translations || (I18n.translations = {});
I18n.translations["en"] = I18n.extend((I18n.translations["en"] || {}), {
  "activerecord": {
    "attributes": {
      "errors": {
        "models": {
          "user": {
            "attributes": {
              "reset_password_token": {
                "invalid": "token หมดอายุการใช้งาน"
              }
            }
          }
        }
      },
      "kpi": {
        "actived": "Usage status",
        "actived_date": "Date/Month/Year of Enforcement",
        "benchmark": "Benchmark (Reference Source/Year) (English)",
        "benchmark_th": "Benchmark (Reference Source/Year) (Thailand)",
        "code": "Indicator Code",
        "disease_code": "Related disease/medical procedure codes (Standing Number, Divisor) (English)",
        "disease_code_th": "Related disease/medical procedure codes (Standing Number, Divisor) (Thailand)",
        "flag_from_thip_1": "Indicator from THIP I",
        "interpret_method": "Method of Result Interpretation (English)",
        "interpret_method_th": "Method of Result Interpretation (Thailand)",
        "meaning": "Definition/Description/Meaning of Indicator (English)",
        "meaning_th": "Definition/Description/Meaning of Indicator (Thailand)",
        "name": "Indicator Name (English)",
        "name_th": "Indicator Name (Thailand)",
        "note": "Note (English)",
        "note_th": "Note (Thailand)",
        "objective": "Objectives of Indicators (English)",
        "objective_th": "Objectives of Indicators (Thailand)",
        "record_method": "Required Information (Standing Number, Divisor) (English)",
        "record_method_th": "Required Information (Standing Number, Divisor) (Thailand)",
        "source": "Source/Reference (English)",
        "source_th": "Source/Reference (Thailand)",
        "updated_at": "Date/Month/Year of the Last Revision",
        "updated_reason": "Reasons for Revision (English)",
        "updated_reason_th": "Reasons for Revision (Thailand)"
      },
      "kpi_inputs": {
        "name": "Name (English)",
        "name_th": "Name (Thailand)"
      },
      "post": {
        "actived": "Usage status",
        "category": "Category",
        "content": "Content",
        "cover": "Cover image",
        "created_at": "Created at",
        "end_date": "วันที่สิ้นสุดประกาศข่าว",
        "ended": "ตั้งค่าวันที่สิ้นสุดประกาศข่าว",
        "pinned": "Pin",
        "pinned_carousel": "Pin at Slideshow",
        "send_mails": "ส่งอีเมลไปยังสมาชิค",
        "title": "Title",
        "updated_at": "Updated at"
      },
      "registered_hospital": {
        "kpi_templates": "Indicator"
      },
      "screened_out": {
        "kpi_id": "Indicator"
      },
      "user": {
        "confirmation_sent_at": "Confirmation sent at",
        "confirmation_token": "Confirmation token",
        "confirmed_at": "Confirmed at",
        "created_at": "Created at",
        "current_password": "Current password",
        "current_sign_in_at": "Current sign in at",
        "current_sign_in_ip": "Current sign in IP",
        "email": "Email",
        "encrypted_password": "Encrypted password",
        "failed_attempts": "Failed attempts",
        "last_sign_in_at": "Last sign in at",
        "last_sign_in_ip": "Last sign in IP",
        "locked_at": "Locked at",
        "password": "Password",
        "password_confirmation": "Password confirmation",
        "password_old": "รหัสผ่านเก่า",
        "remember_created_at": "Remember created at",
        "remember_me": "Remember me",
        "reset_password_sent_at": "Reset password sent at",
        "reset_password_token": "Reset password token",
        "sign_in_count": "Sign in count",
        "unconfirmed_email": "Unconfirmed email",
        "unlock_token": "Unlock token",
        "updated_at": "Updated at"
      }
    },
    "errors": {
      "messages": {
        "record_invalid": "Can not save data",
        "restrict_dependent_destroy": {
          "has_many": "Cannot delete record because dependent %{record} exist",
          "has_one": "Cannot delete record because a dependent %{record} exists"
        }
      },
      "models": {
        "hospital": {
          "attributes": {
            "code": {
              "taken": "Hospital facility code already taken"
            },
            "name": {
              "blank": "Don't leave it blank"
            }
          }
        },
        "kpi": {
          "attributes": {
            "code": {
              "taken": "รหัสตัวชี้วัดได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            }
          }
        },
        "kpi_sheet_input": {
          "attributes": {
            "value": {
              "greater_than": "Too low value",
              "less_than": "Too high value"
            }
          }
        },
        "kpi_sheet_rollback": {
          "attributes": {
            "base": {
              "kpi_empty": "Please select indicator"
            }
          }
        },
        "project": {
          "attributes": {
            "name": {
              "taken": "ชื่อโครงการร่วมนี้ได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            }
          }
        },
        "registered_hospital": {
          "attributes": {
            "code": {
              "overdue": "มีการสมัครใช้งาน หรืออยู่ในขั้นตอนรออนุมัติ",
              "taken": "รหัสสถานพยาบาลได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            },
            "kpi_templates": {
              "blank": "Don't leave it blank"
            }
          }
        },
        "special_group": {
          "attributes": {
            "name": {
              "taken": "ชื่อกลุ่มนี้ได้ถูกสร้างขึ้นในปีงบประมาณนี้แล้ว"
            }
          }
        },
        "user": {
          "attributes": {
            "email": {
              "blank": "Don't leave it blank",
              "taken": "Email already taken"
            },
            "password": {
              "too_short": "Password must be at least 6 characters"
            },
            "password_confirmation": {
              "confirmation": "Passwords do not match"
            }
          }
        }
      }
    },
    "models": {
      "user": {
        "one": "User",
        "other": "Users"
      }
    }
  },
  "analytics": {
    "area": "County",
    "budget_year": "Budget period",
    "budget_year_value": "ประจำปีงบประมาณ %{budget_year}",
    "control_chart": "Control Chart",
    "dashboard": {
      "bar_chart": "The bar graph shows the overall comparison of the indicators.",
      "label": "Dashboard",
      "line_chart": "The line graph shows the overall comparison of the indicators.",
      "result_in_range": "Result in range"
    },
    "delete_filter": "Delete filter",
    "delete_filter_success": "Filter deleted successfully",
    "empty_kpi": "KPI not found",
    "end_round": "End",
    "export_excel": "Export excel",
    "export_pdf": "Export PDF",
    "find_kpi": "Indicators",
    "group": "Group",
    "kpi_progress": {
      "kpi_name": "KPI",
      "kpi_value": "KPI Value",
      "label": "รายงานตัวชี้วัดย้อนหลัง",
      "present": "ปีปัจจุบัน",
      "year_1": "ปีปัจจุบัน - 1",
      "year_2": "ปีปัจจุบัน - 2",
      "year_3": "ปีปัจจุบัน - 3",
      "year_4": "ปีปัจจุบัน - 4"
    },
    "kpi_value": {
      "kpi_value": "KPI Value",
      "label": "Overview Report",
      "median": "Median",
      "n": "N",
      "p25": "P25",
      "p75": "P75"
    },
    "month": "Month",
    "month_budget_year_amount": "เดือน%{month} ปีงบประมาณ %{budget_year} จำนวน %{amount} รพ.",
    "new_member_hospital": "จำนวน รพ.สมาชิกใหม่ %{value}",
    "paid_hospital": "จำนวน ที่ชำระเงินแล้ว %{value} รพ. จำนวนเงิน %{amount} บาท",
    "period_type": "Period",
    "region": "Region",
    "renew_member_hospital": "จำนวน รพ.ต่ออายุ %{value}",
    "report": {
      "active": "Active",
      "all_kpi_number": "Total indicators",
      "all_kpi_value": "Total KPI value",
      "all_user": "Total user",
      "amount": "Amount",
      "approve_number": "จำนวนที่อนุมัติ",
      "area": "In service area",
      "central_line": "Central Line",
      "changwat": "Province",
      "data_range": "Range",
      "disapprove_number": "จำนวนที่ไม่อนุมัติ",
      "financial": "Financial report",
      "general": "General",
      "group": "Hospital type",
      "hos_code": "Hospital facility code",
      "hos_name": "Hospital name",
      "hos_number": "Number of hospital",
      "hos_unit": "Hospitals",
      "hospital_member": "Member report",
      "hospital_payment_status": "รายงานจำนวนและรายละเอียดสมาชิกที่สมัครสมาชิกใหม่ที่ชำระเงินแล้ว / ยังไม่ชำระ",
      "hospital_register": "รายงานข้อมูลการสมัคร / ต่ออายุสมาชิก",
      "hospital_regiter_status": "Registration report",
      "hospital_select_kpi": "รายงานการแสดงค่าตัวชี้วัดที่สมาชิกเลือก",
      "hospital_send_group_kpi": "รายงานร้อยละการส่งข้อมูลเฉลี่ยในแต่ละประเภทสมาชิก",
      "hospital_send_select_kpi": "รายงานการแสดงค่าตัวชี้วัดที่สมาชิกเลือกและส่งข้อมูลเข้าสู่ระบบ",
      "inactive": "Inactive",
      "kpi": "KPI",
      "kpi_area": "รายงานการแสดงข้อมูลแบบเทียบเคียงเขตสุขภาพ",
      "kpi_best10": "Report showing Best Practice values ​​> 10 (high to low , low to high)",
      "kpi_choice": "รายงานลำดับตัวชี้วัดที่ถูกเลือกเปรียบเทียบจากมากไปหาน้อย",
      "kpi_group": "รายงานค่าสถิติตัวชี้วัดรายกลุ่มสมาชิก",
      "kpi_region": "รายงานการแสดงค่าตัวชี้วัดตนเองเมื่อเทียบภูมิภาค",
      "kpi_report": "Report",
      "kpi_save_status": "รายงานสถานะการบันทึกตัวชี้วัด",
      "kpi_size": "รายงานการแสดงค่าตัวชี้วัดตนเองเมื่อเทียบขนาดเตียง",
      "kpi_subgroup": "รายงานค่าสถิติตัวชี้วัดข้ามกลุ่มสมาชิก (Sub Group)",
      "kpi_top_send": "รายงานการแสดงตัวชี้วัดที่มีอัตราการส่งข้อมูลมากที่สุด (เลือก, ส่ง)",
      "kpi_unit": "รายงานการแสดงค่าตัวชี้วัดตนเองเมื่อเทียบกับสังกัดอื่น",
      "kpi_value": "KPI value",
      "kpi_value_en": "Kpi Value",
      "kpi_value_list": "รายงานค่าสถิติแบบเลือก",
      "kpi_value_report": "Indicator display report",
      "label": "Report",
      "max": "Max",
      "mean": "Mean",
      "median": "Median",
      "min": "Min",
      "n": "N",
      "new_member": "New member",
      "not_paid": "Unpaid",
      "not_select_kpi_number": "จำนวนตัวชี้วัดที่ไม่ได้เลือก",
      "paid": "Paid",
      "paid_member": "Paid member report",
      "payment_seq": "Invoice number",
      "payment_status": "Payment status",
      "payment_status_sheet": "Hospitals",
      "percentage": "percentage",
      "percentile_25": "Percentile 25",
      "percentile_75": "Percentile 75",
      "reason_1": "Reason 1",
      "reason_2": "Reason 2",
      "reason_3": "Reason 3",
      "reason_4": "Reason 4",
      "reason_5": "Reason 5",
      "reason_6": "Reason 6",
      "region": "Region",
      "renew": "Renew member",
      "rollback_request_number": "จำนวนที่ขอปลดล็อคทั้งหมด",
      "rollback_request_reasons": "รายงานรายละเอียดการปลดล็อคระบบ",
      "sd1": "SD1",
      "sd1_neg": "-SD1",
      "sd2": "SD2",
      "sd2_neg": "-SD2",
      "sd3": "SD3",
      "sd3_neg": "-SD3",
      "select_kpi_number": "จำนวนตัวชี้วัดที่เลือก",
      "selected_kpi_member": "รายชื่อสมาชิกที่เลือกตัวชี้วัด",
      "send_kpi_nubmer": "จำนวนตัวชี้วัดที่ส่ง",
      "send_percentage": "อัตราร้อยละการส่ง",
      "size": "Bed",
      "time": "Time(s)",
      "total": "Total",
      "total_member": "Total member",
      "trim_mean": "Trim Mean",
      "type": "Type",
      "unit": "Affiliation",
      "unlock": "รายงานการปลดล็อคระบบ",
      "users": "User report"
    },
    "round": "รอบการส่ง%{round}",
    "save_filter": "Save filter",
    "save_filter_success": "Filter saved successfully",
    "select_kpi": "Select indicators",
    "size": "Bed",
    "special_group": "Special group",
    "start_round": "Start",
    "total_amount": "จำนวนเงินทั้งหมด %{amount} บาท",
    "total_hospital": "จำนวน รพ.ทั้งหมด %{value}",
    "unit": "Affiliation",
    "unpaid_hospital": "จำนวน ที่ยังไม่ชำระเงิน %{value} รพ. จำนวนเงิน %{amount} บาท",
    "user_amount": "จำนวนผู้ใช้งานระบบ %{value} คน",
    "year": "Year",
    "year_type": "Type"
  },
  "app": {
    "abbrev": "THIP",
    "about": "About THIP",
    "about_thip": "About THIP",
    "about_us": "About Us",
    "about_us_en": "About Us",
    "about_us_no_data": "No data available at the moment",
    "accept": "Accept",
    "add": "Add",
    "approve": "Approve",
    "are_you_sure": "Are you sure?",
    "back": "Back",
    "bath": "Baht",
    "benchmarking": "Benchmarking",
    "can_not_delete": "Can not delete",
    "cancel": "Cancel",
    "come_be_part_of_thip": "Come Join THIP",
    "contact_location": "88/39 National Health Building, 5th Floor, Soi 6, Ministry of Public Health, Tiwanon Road, Talat Khwan Subdistrict, Mueang Nonthaburi District 11000.",
    "contact_tel": "Tel : 0-2832-9400 Fax : 0-2832-9540",
    "contact_us": "Contact Us",
    "delete": "Delete",
    "disapprove": "Disapproved",
    "download": "Download",
    "edit": "Edit",
    "en_name": "(English)",
    "export_data": "Export data",
    "failed": "Failed",
    "have_no": "Empty",
    "home": "Home",
    "knowledge": "Knowledge",
    "kpi": "Indicators",
    "login": "Login",
    "login_or_register": "Registration",
    "member": "Members",
    "month": "Month",
    "month_year": "Month/Year",
    "monthly": "Monthly",
    "name": "Thailand Hospital Indicator Program",
    "name_alt": "Thailand Hospital Indicator Program (THIP)",
    "name_th": "ระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล",
    "name_th_alt": "ระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP)",
    "next": "Next",
    "no_data": "No data",
    "not_select": "Unselected",
    "not_send": "Not send",
    "not_sent": "The data has not yet been submitted",
    "not_specified": "Unspecified",
    "note": "Note",
    "ok": "OK",
    "organization_detail_1": "© สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน) : The Healthcare Accreditation Institute (Public Organization)",
    "organization_detail_2": "Tel 0-2832-9400 , Fax 0-2832-9540",
    "organization_detail_alt": "The Healthcare Accreditation Institute (Public Organization)",
    "organization_name": "The Healthcare Accreditation Institute (Public Organization)",
    "other": "Other",
    "pdpa_policy": "Personal Data Protection Act (PDPA)",
    "person": "People",
    "please_select": "Please select",
    "reason": "Reason",
    "report": "Reports",
    "save": "Save",
    "save_failed": "Failed to save the data",
    "save_successful": "Data has been saved successfully",
    "search": "Search",
    "select": "Select",
    "select_all": "Select all",
    "send": "Send",
    "sent": "The data has been submitted",
    "sent_data": "Send data",
    "specified": "Specify",
    "succeed": "Successed",
    "thip_are_support": "THIP Support",
    "thip_detail_1": "เครื่องมือเทคโนโลยีระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล",
    "thip_detail_2": "ให้เกิดการการพัฒนาระบบบริการสุขภาพมีคุณภาพ และไว้วางใจได้ด้วยมาตรฐาน HA",
    "thip_detail_3": "\"มีวัตถุประสงค์เพื่อส่งเสริมให้สถานพยาบาลใช้ประโยชน์จากการเปรียบเทียบตัวชี้วัด (benchmarking) ในการ ยกระดับผลลัพธ์ขององค์กร พัฒนาตัวชี้วัดเปรียบเทียบสำหรับสถานพยาบาลในประเทศไทยที่จะเป็นเครื่องมือ ชี้ทิศทางและเร่งรัดการพัฒนาคุณภาพบริการสุขภาพ พัฒนาองค์ความรู้จากการปฏิบัติที่เป็นเลิศ และพัฒนากลไก เครื่องมือเทคโนโลยีระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล\"",
    "thip_today": "THIP Today",
    "to": "to",
    "what_is_thip": "What is THIP?",
    "year": "Year",
    "yearly": "Yearly"
  },
  "budget_year": {
    "created_notice": "เพิ่มปีงบประมาณสำเร็จ",
    "deadline_date": "วันสุดท้ายสำหรับการบันทึกข้อมูล",
    "destroyed_notice": "ลบปีงบประมาณสำเร็จ",
    "label": "Fiscal Year",
    "month": "เดือน",
    "note": "คำอธิบายสำหรับแจ้งให้โรงพยาบาลสมาชิกทราบ",
    "title": "ข้อมูลปีงบประมาณ",
    "updated_notice": "แก้ไขปีงบประมาณสำเร็จ"
  },
  "change_passsword": {
    "updated_notice": "Password changed successfully"
  },
  "change_password": {
    "updated_notice": "Password changed successfully"
  },
  "config": {
    "budget_year": {
      "add": "Add year",
      "allow_until_date": "อนุญาตให้มีการแก้ไขข้อมูลการลงทะเบียนจนถึงวันที่",
      "fee": "Fee",
      "group_unit": "ระดับศักยภาพ",
      "info": "Information",
      "invoice_code": "รหัสใบจ่ายเงิน",
      "invoice_order": "ลำดับเลขที่จ่ายเงิน",
      "label": "Budget period",
      "payment_end_date": "วันที่สุดท้ายในการชำระเงิน",
      "project_code": "รหัสโครงการ",
      "register_status": "สถานะการเปิดการลงทะเบียน",
      "running_number": "จำนวนหลัก Running Number",
      "start_fill_form_date": "วันที่เริ่มกรอกข้อมูล",
      "title": "Year"
    },
    "fee": {
      "can_not_add_to_list": "ไม่สามารถเพิ่มสถานพยาบาลนี้ในรายการยกเว้นค่าลงทะเบียนได้",
      "except_fee": "ยกเว้นค่าลงทะเบียน"
    },
    "hospital_group": {
      "edit": "แก้ไขตัวชี้วัดกลุ่ม",
      "edit_member": "แก้ไขสมาชิกกลุ่ม"
    },
    "kpi": {
      "edit": "แก้ไขข้อมูลตัวชี้วัด"
    },
    "member": {
      "edit": "แก้ไขสมาชิก"
    },
    "other": {
      "admin": "ผู้ดูแลระบบ",
      "admin_type": "ประเภทผู้ดูแลระบบ",
      "description": "คำอธิบาย",
      "home_page": "หน้าแรกเมื่อเข้าสู่ระบบ",
      "usage_status": "สถานะการใช้งาน",
      "user_type": "ประเภทผู้ใช้งาน"
    },
    "project": {
      "add": "เพิ่มโครงการร่วม",
      "budget_year": "Budget period",
      "created_notice": "เพิ่มโครงการร่วมสำเร็จ",
      "description": "Description",
      "destroyed_notice": "ลบโครงการร่วมสำเร็จ",
      "edit": "แก้ใขโครงการร่วม",
      "edit_kpi": "แก้ไขตัวชี้วัดโครงการร่วม",
      "info": "Information",
      "name": "Name",
      "title": "Co-project",
      "updated_kpi_notice": "แก้ไขข้อมูลตัวชี้วัดสำเร็จ",
      "updated_notice": "แก้ไขโครงการร่วมสำเร็จ"
    },
    "special_group": {
      "add": "เพิ่มกลุ่มพิเศษ",
      "budget_year": "ปีงบประมาณ",
      "edit": "แก้ใขกลุ่มพิเศษ",
      "edit_kpi": "แก้ไขตัวชี้วัดกลุ่ม",
      "edit_member": "แก้ไขสมาชิกกลุ่ม",
      "info": "ข้อมูลกลุ่มพิเศษ",
      "name": "ชื่อกลุ่ม",
      "title": "กลุ่มพิเศษ"
    },
    "system": {
      "cc_internal_authen": "cc.internal.authen",
      "database_version": "database.version",
      "domain": "system.domain",
      "external_cc_url": "external.cc.url",
      "external_ianalysis_url": "external.ianalysis.url",
      "external_jrs_url": "external.jrs.url",
      "hospital_all_mailgroup": "hospital.all.mailgroup",
      "hospital_all_mailgroup_extends": "hospital.all.mailgroup.extends",
      "log": "log",
      "log_access": "log.access",
      "log_access_console": "log.access.console",
      "log_access_header": "log.access.header",
      "log_console": "log.console",
      "log_full_trace": "log.full.trace",
      "register_payin_company_code": "register.payin.company.code",
      "register_payin_ref2_number": "register.payin.ref2.number",
      "title": "System",
      "trial_period": "number of trial days",
      "upload_failed": "Upload failed",
      "upload_success": "Upload success"
    }
  },
  "date": {
    "abbr_day_names": [
      "Sun",
      "Mon",
      "Tue",
      "Wed",
      "Thu",
      "Fri",
      "Sat"
    ],
    "abbr_month_names": [
      null,
      "Jan.",
      "Feb.",
      "Mar.",
      "Apr.",
      "May",
      "June",
      "July",
      "Aug.",
      "Sept.",
      "Oct.",
      "Nov.",
      "Dec."
    ],
    "day_names": [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday"
    ],
    "formats": {
      "long": "%B %d, %Y"
    },
    "month_names": [
      null,
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December"
    ],
    "order": [
      "year",
      "month",
      "day"
    ],
    "period_types": [
      "monthly",
      "yearly",
      "quarterly"
    ],
    "year_types": [
      "Calendar Year",
      "Budget Year"
    ]
  },
  "datetime": {
    "distance_in_words": {
      "about_x_hours": {
        "one": "about 1 hour",
        "other": "about %{count} hours"
      },
      "about_x_months": {
        "one": "about 1 month",
        "other": "about %{count} months"
      },
      "about_x_years": {
        "one": "about 1 year",
        "other": "about %{count} years"
      },
      "almost_x_years": {
        "one": "almost 1 year",
        "other": "almost %{count} years"
      },
      "half_a_minute": "half a minute",
      "less_than_x_minutes": {
        "one": "less than a minute",
        "other": "less than %{count} minutes"
      },
      "less_than_x_seconds": {
        "one": "less than 1 second",
        "other": "less than %{count} seconds"
      },
      "over_x_years": {
        "one": "over 1 year",
        "other": "over %{count} years"
      },
      "x_days": {
        "one": "1 day",
        "other": "%{count} days"
      },
      "x_minutes": {
        "one": "1 minute",
        "other": "%{count} minutes"
      },
      "x_months": {
        "one": "1 month",
        "other": "%{count} months"
      },
      "x_seconds": {
        "one": "1 second",
        "other": "%{count} seconds"
      }
    },
    "prompts": {
      "day": "Day",
      "hour": "Hour",
      "minute": "Minute",
      "month": "Month",
      "second": "Seconds",
      "year": "Year"
    }
  },
  "devise": {
    "confirmations": {
      "confirmed": "Your email address has been successfully confirmed.",
      "new": {
        "resend_confirmation_instructions": "Resend confirmation instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to confirm your email address in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive an email with instructions for how to confirm your email address in a few minutes."
    },
    "failure": {
      "already_authenticated": "You are already signed in.",
      "inactive": "Your account is not activated yet.",
      "invalid": "Invalid %{authentication_keys} or password.",
      "last_attempt": "You have one more attempt before your account is locked.",
      "locked": "Your account is locked.",
      "not_found_in_database": "Invalid %{authentication_keys} or password.",
      "timeout": "Your session expired. Please sign in again to continue.",
      "unauthenticated": "You need to sign in or sign up before continuing.",
      "unconfirmed": "You have to confirm your email address before continuing.",
      "user": {
        "actived": "บัญชีของคุณถูกปิดใช้งาน"
      }
    },
    "mailer": {
      "confirmation_instructions": {
        "action": "Confirm my account",
        "greeting": "Welcome %{recipient}!",
        "instruction": "You can confirm your account email through the link below:",
        "subject": "Confirmation instructions"
      },
      "email_changed": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your email has been changed to %{email}.",
        "message_unconfirmed": "We're contacting you to notify you that your email is being changed to %{email}.",
        "subject": "Email Changed"
      },
      "password_change": {
        "greeting": "Hello %{recipient}!",
        "message": "We're contacting you to notify you that your password has been changed.",
        "subject": "Password Changed"
      },
      "reset_password_instructions": {
        "action": "Change my password",
        "greeting": "Hello %{recipient}!",
        "instruction": "Someone has requested a link to change your password. You can do this through the link below.",
        "instruction_2": "If you didn't request this, please ignore this email.",
        "instruction_3": "Your password won't change until you access the link above and create a new one.",
        "subject": "Reset password instructions"
      },
      "unlock_instructions": {
        "action": "Unlock my account",
        "greeting": "Hello %{recipient}!",
        "instruction": "Click the link below to unlock your account:",
        "message": "Your account has been locked due to an excessive number of unsuccessful sign in attempts.",
        "subject": "Unlock instructions"
      }
    },
    "omniauth_callbacks": {
      "failure": "Could not authenticate you from %{kind} because \"%{reason}\".",
      "success": "Successfully authenticated from %{kind} account."
    },
    "passwords": {
      "edit": {
        "change_my_password": "Change my password",
        "change_your_password": "Change your password",
        "confirm_new_password": "Confirm new password",
        "new_password": "New password"
      },
      "new": {
        "forgot_your_password": "Forgot your password?",
        "send_me_reset_password_instructions": "Send me reset password instructions"
      },
      "no_token": "You can't access this page without coming from a password reset email. If you do come from a password reset email, please make sure you used the full URL provided.",
      "send_instructions": "You will receive an email with instructions on how to reset your password in a few minutes.",
      "send_paranoid_instructions": "If your email address exists in our database, you will receive a password recovery link at your email address in a few minutes.",
      "updated": "Your password has been changed successfully. You are now signed in.",
      "updated_not_active": "Your password has been changed successfully."
    },
    "registrations": {
      "destroyed": "Bye! Your account has been successfully cancelled. We hope to see you again soon.",
      "edit": {
        "are_you_sure": "Are you sure?",
        "cancel_my_account": "Cancel my account",
        "currently_waiting_confirmation_for_email": "Currently waiting confirmation for: %{email}",
        "leave_blank_if_you_don_t_want_to_change_it": "leave blank if you don't want to change it",
        "title": "Edit %{resource}",
        "unhappy": "Unhappy?",
        "update": "Update",
        "we_need_your_current_password_to_confirm_your_changes": "we need your current password to confirm your changes"
      },
      "new": {
        "sign_up": "Sign up"
      },
      "signed_up": "Welcome! You have signed up successfully.",
      "signed_up_but_inactive": "You have signed up successfully. However, we could not sign you in because your account is not yet activated.",
      "signed_up_but_locked": "You have signed up successfully. However, we could not sign you in because your account is locked.",
      "signed_up_but_unconfirmed": "A message with a confirmation link has been sent to your email address. Please follow the link to activate your account.",
      "update_needs_confirmation": "You updated your account successfully, but we need to verify your new email address. Please check your email and follow the confirmation link to confirm your new email address.",
      "updated": "Your account has been updated successfully.",
      "updated_but_not_signed_in": "Your account has been updated successfully, but since your password was changed, you need to sign in again"
    },
    "sessions": {
      "already_signed_out": "Signed out successfully.",
      "new": {
        "sign_in": "Log in"
      },
      "signed_in": "Signed in successfully.",
      "signed_out": "Signed out successfully."
    },
    "shared": {
      "links": {
        "back": "Back",
        "didn_t_receive_confirmation_instructions": "Didn't receive confirmation instructions?",
        "didn_t_receive_unlock_instructions": "Didn't receive unlock instructions?",
        "forgot_your_password": "Forgot your password?",
        "sign_in": "Log in",
        "sign_in_with_provider": "Sign in with %{provider}",
        "sign_up": "Sign up"
      },
      "minimum_password_length": {
        "one": "(%{count} character minimum)",
        "other": "(%{count} characters minimum)"
      }
    },
    "unlocks": {
      "new": {
        "resend_unlock_instructions": "Resend unlock instructions"
      },
      "send_instructions": "You will receive an email with instructions for how to unlock your account in a few minutes.",
      "send_paranoid_instructions": "If your account exists, you will receive an email with instructions for how to unlock it in a few minutes.",
      "unlocked": "Your account has been unlocked successfully. Please sign in to continue."
    }
  },
  "erp": {
    "all_data": "ข้อมูลนำเข้าทั้งหมด",
    "all_export": "ส่งออกทั้งหมด",
    "amount": "จำนวนรับชำระ",
    "data_download_error": "ดาวน์โหลดข้อมูลผิดพลาด",
    "duplicate_data": "ข้อมูลซ้ำซ้อน",
    "failed_to_download": "ดาวน์โหลดข้อมูลไม่ผ่าน",
    "import_transaction_file": "นำเข้า Transaction",
    "paid_date": "วันที่รับชำระ",
    "passed_data": "ข้อมูลผ่าน",
    "record": "record",
    "selected_export": "ส่งออกที่เลือก",
    "send_to_erp_succeed": "ส่งข้อมูลไปยัง ERP เสร็จแล้ว",
    "sent_states": {
      "all_data": "all_data",
      "succeed": "succeed",
      "unsuccessful": "unsuccessful",
      "waiting": "waiting"
    },
    "status": "status",
    "thead_ref_1": "ลำดับเลขที่จ่ายเงิน (Ref 1)",
    "thead_ref_2": "รหัสใบจ่ายเงิน (Ref 2)",
    "unpassed_data": "ข้อมูลไม่ผ่าน",
    "upload_failed_notice": "อัพโหลดข้อมูลไม่สำเร็จ เนื้องจากตรวจสอบไม่ผ่าน หรือ ไม่เจอข้อมูล"
  },
  "error": {
    "access_denied": "Access Denied",
    "bad_request": "Bad Request",
    "can_not_save": "Can not save",
    "internal_server_error": "Internal Server Error",
    "not_found": "Page Not Found",
    "server_error_try_again": "Server error. Please try again later",
    "title": "Error occurs",
    "unprocessable_entity": "Unprocessable Entity"
  },
  "errors": {
    "connection_refused": "Oops! Failed to connect to the Web Console middleware.\nPlease make sure a rails development server is running.\n",
    "format": "%{attribute} %{message}",
    "messages": {
      "accepted": "must be accepted",
      "already_confirmed": "was already confirmed, please try signing in",
      "blank": "can't be blank",
      "confirmation": "doesn't match %{attribute}",
      "confirmation_period_expired": "needs to be confirmed within %{period}, please request a new one",
      "empty": "can't be empty",
      "equal_to": "must be equal to %{count}",
      "even": "must be even",
      "exclusion": "is reserved",
      "expired": "has expired, please request a new one",
      "greater_than": "must be greater than %{count}",
      "greater_than_or_equal_to": "must be greater than or equal to %{count}",
      "in": "must be in %{count}",
      "inclusion": "is not included in the list",
      "invalid": "is invalid",
      "less_than": "must be less than %{count}",
      "less_than_or_equal_to": "must be less than or equal to %{count}",
      "model_invalid": "Validation failed: %{errors}",
      "not_a_number": "is not a number",
      "not_an_integer": "must be an integer",
      "not_found": "not found",
      "not_locked": "was not locked",
      "not_saved": {
        "one": "1 error prohibited this %{resource} from being saved:",
        "other": "%{count} errors prohibited this %{resource} from being saved:"
      },
      "odd": "must be odd",
      "other_than": "must be other than %{count}",
      "present": "must be blank",
      "required": "must exist",
      "taken": "has already been taken",
      "too_long": {
        "one": "is too long (maximum is 1 character)",
        "other": "is too long (maximum is %{count} characters)"
      },
      "too_short": {
        "one": "is too short (minimum is 1 character)",
        "other": "is too short (minimum is %{count} characters)"
      },
      "wrong_length": {
        "one": "is the wrong length (should be 1 character)",
        "other": "is the wrong length (should be %{count} characters)"
      }
    },
    "models": {
      "user": {
        "attributes": {
          "reset_password_token": {
            "invalid": "รหัสผ่านไม่ถูกต้อง"
          }
        }
      }
    },
    "unacceptable_request": "A supported version is expected in the Accept header.\n",
    "unavailable_session": "Session %{id} is no longer available in memory.\n\nIf you happen to run on a multi-process server (like Unicorn or Puma) the process\nthis request hit doesn't store %{id} in memory. Consider turning the number of\nprocesses/workers to one (1) or using a different server in development.\n"
  },
  "feedback": {
    "body": "Body",
    "comment": "Comment",
    "created_at": "Created At",
    "created_notice": "Feedback was created",
    "destroyed_notice": "Feedback was deleted",
    "hospital": "Hospital",
    "info": "Info",
    "list": "List",
    "new": "New",
    "no_data": "No Feedbacks",
    "type": "Type",
    "user": "User"
  },
  "flash": {
    "actions": {
      "create": {
        "notice": "%{resource_name} was successfully created."
      },
      "destroy": {
        "alert": "%{resource_name} could not be destroyed.",
        "notice": "%{resource_name} was successfully destroyed."
      },
      "update": {
        "notice": "%{resource_name} was successfully updated."
      }
    }
  },
  "footer": {
    "about_us": "About Us",
    "contact_us": "Contact Us",
    "get_started": "Get Started",
    "navigation": "Navigation",
    "version": "version: %{version}"
  },
  "form": {
    "email_already_registered": "This email is already registered",
    "email_must_unique": "Email must be unique",
    "enter_valid_value": "Please enter valid value",
    "enter_value": "Please fill value",
    "hospital_not_found": "Hospital not found",
    "kpi_can_only_be_decimal": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนทศนิยมเท่านั้น",
    "kpi_can_only_be_integer": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนเต็มเท่านั้น",
    "kpi_can_only_be_numeral": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนนับเท่านั้น",
    "kpi_can_only_be_positive_decimal": "ตัวชี้วัดนี้ สามารถกรอกได้แค่จำนวนทศนิยมบวกเท่านั้น",
    "name_must_unique": "Name must be unique",
    "not_yet_certification_process": "ยังไม่เข้ากระบวนการรับรอง",
    "select_atleast_one_kpi": "Please select at least one indicator",
    "select_date": "Please select date"
  },
  "helpers": {
    "page_entries_info": {
      "entry": {
        "one": "entry",
        "other": "entries",
        "zero": "entries"
      },
      "more_pages": {
        "display_entries": "Displaying %{entry_name} <b>%{first}&nbsp;-&nbsp;%{last}</b> of <b>%{total}</b> in total"
      },
      "one_page": {
        "display_entries": {
          "one": "Displaying <b>1</b> %{entry_name}",
          "other": "Displaying <b>all %{count}</b> %{entry_name}",
          "zero": "No %{entry_name} found"
        }
      }
    },
    "select": {
      "prompt": "Please select"
    },
    "submit": {
      "create": "Create %{model}",
      "submit": "Save %{model}",
      "update": "Update %{model}"
    }
  },
  "home": {
    "from": "From %{hospital_name}",
    "welcome": "Welcome %{name}"
  },
  "hospital": {
    "actived": "Usage status",
    "actual_size": "Actual number of beds",
    "add_data": "Add member",
    "add_data_instruction": "Click the button to add new hospital",
    "address_amphoe_changwat": "Address (Province, District)",
    "address_en": "Hospital address (English)",
    "address_th": "Hospital address (Thai language)",
    "all_government_group_unit": "All government",
    "amphoe": "District",
    "area": "In service area",
    "bed": "bed",
    "bed_size": "Bed",
    "bill_seq": "Invoice number",
    "budget_year": "Budget period",
    "cert_certified_date": "Date of certification",
    "cert_exp_date": "Certification expiration date",
    "certificate": "Other certificates was not issued by HA (If other please specify)",
    "changwat": "Province",
    "code": "Medical facility code",
    "code_alt": "Hospital facility code",
    "company_code": "Company Code",
    "contact_info": "Contact information",
    "coop1": "1st THIP Project Coordinator",
    "coop1_first_name": "First name of project coordinator",
    "coop1_last_name": "Last name of project coordinator",
    "coop1_pre_name": "Titles of project coordinator",
    "coop2": "2nd THIP Project Coordinator",
    "coop2_first_name": "First name of project coordinator",
    "coop2_last_name": "Last name of project coordinator",
    "coop2_pre_name": "Titles of project coordinator",
    "coop_email": "Email",
    "coop_name": "Name of project coordinator",
    "coop_phone_number": "Phone number",
    "created_notice": "Data added successfully",
    "destroyed_notice": "Data deleted successfully",
    "director": "Director/top management",
    "director_first_name": "First name of director/top management",
    "director_last_name": "Last name of director/top management",
    "director_name": "Name of director/top management",
    "director_pre_name": "Titles of director/top management",
    "download_invoice": "Download invoice",
    "download_register_form": "Download registration form",
    "e_tax_mail": "Email for send e-Tax",
    "edit": "Edit hospital",
    "edit_user": "Edit user",
    "email": "Email",
    "email_report": "Email",
    "empty_data": "No data",
    "fax": "Hospital fax number",
    "fee": "Registration fee",
    "form": "Hospital form",
    "group": "Hospital type",
    "group_unit": "Potential level",
    "ha_certified_date": "Date of certification",
    "ha_expire_date": "Certification expiration date",
    "ha_status": "HA certification status",
    "hos_location": "Hospital location",
    "hospital_number": "จำนวน รพ.",
    "info": "Member Information",
    "invoice_name": "Receipt Name",
    "kpi_limitation": "Note for limitation of the selection mandatory indicators",
    "kpi_start_round": "Start saving data from",
    "last_month_submit": "Month of last submission",
    "latitude": "Latitude",
    "location": "Coordinates",
    "longitude": "Longitude",
    "name": "Name of hospital",
    "name_alt": "Hospital name",
    "name_en": "Name of hospital (English)",
    "name_or_code": "Name or medical facility code",
    "name_th": "Name of hospital (Thai language)",
    "new": "Add hostpital",
    "new_member": "New member",
    "no_data_has_sent": "Data not sent yet",
    "no_data_in_budget_year": "No information in budget year",
    "not_found": "Hospital not found",
    "not_found_and_use_current_code": "Hospital not found. Click for use %{code} as facility code",
    "not_found_in_budget_year": "No registration data in %{budget_year} (Budget period)",
    "not_found_info": "No information found",
    "not_registered": "Not registered yet",
    "note": "Note",
    "other_certificate": "Other certificates was not issued by HA",
    "other_info": "Other information",
    "payment_date": "Paid date",
    "payment_note": "Receipt Address",
    "payment_postal_code": "Postcode",
    "payment_sequence": "Payment sequence",
    "phone_number": "Phone number",
    "postal_code": "ZIP code",
    "print_invoice": "Print invoice",
    "project": "Co-project",
    "qic_phone_number": "Telephone number of the Quality Improvement Center of the Hospital",
    "quic_email": "E-mail of the Quality Improvement Center of the Healthcare Facility",
    "register_date": "Registration date",
    "register_successful": "Registration successfully",
    "register_successful_description_1": "Please wait for admin approval",
    "register_successful_description_2": "The system sent registration information to project coordinator",
    "register_summary": "Registration summary",
    "register_trial_successful": "Trial registration successfully",
    "register_trial_successful_description_1": "%{trial_days} days free trial",
    "register_trial_successful_description_2": "to (%{expiry_date})",
    "register_type": "Registration type",
    "registered": "This hospital has already registered",
    "registered_size": "Number of beds allowed",
    "renew_member": "Renew",
    "rollback": "Unlock submission",
    "search": "Search",
    "search_instruction": "Enter the 5 digits hospital facility code. You can find the hospital facility code from www.thcc.or.th or contact HA. 0-2832-9505",
    "select_indicator": "Select indicators",
    "selected_kpi": "Selected indicators",
    "sent_kpi": "Sent indicators",
    "size": "Hospital size",
    "start_round": "Start saving data from",
    "tambon": "Sub-district",
    "tax_id": "Tax number",
    "title": "Hospital",
    "total": "Total hospital",
    "trial": "Trial",
    "unit": "Affiliation",
    "updated_notice": "Data updated successfully"
  },
  "hospital_group": {
    "created_notice": "Hospital group created successfully",
    "destroyed_notice": "Hospital group deleted successfully",
    "updated_notice": "Hospital group updated successfully"
  },
  "invoice": {
    "accept_for_amount": "รับชำระเงินตามจำนวนข้างต้นเท่านั้น",
    "address": "ที่อยู่",
    "amount": "จำนวนเงิน",
    "amount_en": "Amount",
    "amount_to_pay": "จำนวนเงินที่ต้องชำระ",
    "bank": "ธนาคาร",
    "bank_name": "ชื่อธนาคารและสาขา",
    "bank_part": "ส่วนของธนาคาร",
    "bill_no": "Bill No. / Ref.1",
    "branch": "สาขา",
    "branch_en": "Bank/Branch",
    "branch_id": "รหัสสาขา(สำนักงาน)",
    "cash": "เงินสด",
    "cash_en": "Cash",
    "character": "ตัวอักษร",
    "chq_due_date": "เช็คลงวันที่",
    "chq_due_date_en": "Chq Due Date",
    "chq_no": "เลขที่เช็ค",
    "chq_no_en": "Chq No.",
    "company_code": "Company Code",
    "counter_only_ktb": "การชำระที่เคาน์เตอร์ สำหรับธนาคารกรุงไทยเท่านั้น",
    "customer_name": "ชื่อลูกค้า",
    "customer_path": "ส่วนของลูกค้า",
    "deputy_director": "รองผู้อำนวยการ",
    "e_payment": "ช่องทางอิเล็กทรอนิกส์",
    "expire_after_due_date": "ไม่รับชำระเงินหลังวันที่กำหนด",
    "for": "ค่าใช้จ่ายสำหรับ",
    "for_budget_year": "ค่าบำรุงของสมาชิกโครงการพัฒนาระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP) ประจำปีงบประมาณ %{budget_year}",
    "for_hospital": "ค่าบำรุงของสมาชิกโครงการพัฒนาระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP) ของโรงพยาบาลระดับ%{enrollment} ประจำปีงบประมาณ %{budget_year}",
    "form_instruction": "กรุณากรอกเอกสารนี้ให้ครบถ้วน แล้วนำไปชำระได้ที่เคาน์เตอร์ธนาคารกรุงไทย",
    "get_money_date": "วันที่รับเงิน",
    "head_of_financial_accounting": "หัวหน้าฝ่ายการเงินการบัญชี",
    "issuer": "ผู้ออกใบแจ้งรายการจ่ายเงิน",
    "ktb_received_from_thip": "KTB รับเงินจาก THIP",
    "list": "รายการ",
    "not_include_vat": "จำนวนไม่รวมVat",
    "note": "หมายเหตุ : สถาบันได้รับการยกเว้นหักภาษี ณ ที่จ่าย",
    "pay_by": "รับเงินจาก",
    "payment_date": "วันที่ชำระเงิน",
    "payment_method": "1=เงินสด 2=เช็ค 3=โอน",
    "payment_type": "ประเภทรายการรับเงิน",
    "print_at": "พิมพ์ ณ วันที่",
    "receipt_no": "เลขที่ใบเสร็จรับเงิน",
    "ref_1": "Ref.1",
    "ref_2": "Ref.2",
    "tax_id": "รหัสประจำตัวผู้เสียภาษี",
    "title": "ใบแจ้งรายการจ่ายเงิน",
    "total": "รวมเป็นเงินทั้งสิ้น",
    "total_amount": "ยอดรวม จำนวนเงินที่ชำระ",
    "total_amount_bath": "จำนวนเงินรวม (บาท)",
    "total_include_vat": "จำนวนเงินรวมVat",
    "total_payment_en": "Total Payment",
    "transaction_date": "วันที่ทำรายการ : %{date}",
    "unit_amount": "จำนวนหน่วย",
    "vat": "Vat%",
    "vat_amount": "จำนวนVat"
  },
  "kpi": {
    "above": "above",
    "actived": "Usage status",
    "actived_date": "Date/Month/Year of Enforcement",
    "add": "Add indicator",
    "add_group": "Add group",
    "add_instruction": "กดปุ่มเพิ่มตัวชี้วัด เพื่อเพิ่มตัวชี้วัด",
    "add_sub_group": "Add subgroup",
    "approval_status": "Approval Status",
    "audit": {
      "hospital_list_not_sent_this_month": "รายชื่อสถานพยาบาลที่ยังไม่ส่งเดือนนี้",
      "hospital_list_sent_this_month": "รายชื่อสถานพยาบาลที่ส่งเดือนนี้แล้ว",
      "label": "ตรวจสอบการบันทึกตัวชี้วัด",
      "level": "ระดับคุณภาพ",
      "sent_16th": "รายงานการส่งข้อมูล ณ วันที่ 16",
      "verification": "การตรวจสอบ"
    },
    "benchmark": "Benchmark (Reference Source/Year)",
    "code": "Indicator Code",
    "continuous": "Associating values",
    "created_notice": "เพิ่มตัวชี้วัดสำเร็จ",
    "data_of_hospital_save_successful": "ข้อมูลของ %{hospital_name} ในช่วง %{round} ได้บันทึกเข้าระบบแล้ว",
    "denominator": "ตัวหาร",
    "denominator_detail": "Divisor Detail",
    "destroyed_notice": "ลบตัวชี้วัดสำเร็จ",
    "disease_code": "Related disease/medical procedure codes",
    "down": "down",
    "edit": "Edit indicator",
    "edit_formula": "Edit Formula",
    "edit_group": "Edit group",
    "flag_from_thip_1": "Indicator from THIP I",
    "formula": {
      "created_notice": "เพิ่มสูตรคำนวณสำเร็จ",
      "destroyed_notice": "ลบสูตรคำนวณสำเร็จ",
      "label": "Formula",
      "updated_notice": "แก้ไขสูตรคำนวณสำเร็จ"
    },
    "formula_detail": "Formula detail",
    "formula_kpi": "Formula for Calculation",
    "from": "from",
    "group": {
      "created_notice": "เพิ่มกลุ่มตัวชี้วัดสำเร็จ",
      "destroyed_notice": "ลบกลุ่มตัวชี้วัดสำเร็จ",
      "updated_notice": "แก้ไขกลุ่มตัวชี้วัดสำเร็จ"
    },
    "group_detail": "Group detail",
    "group_info": "Group detail",
    "group_info_kpi": "Group detail",
    "group_name": "Group name (EN)",
    "group_name_th": "Group name (TH)",
    "group_parent": "Category of Indicator",
    "history": {
      "fill_and_sent": "Complete",
      "fill_not_sent": "Unsend",
      "info_of": "Information of",
      "no_data": "There is no metric recording data at this time.",
      "not_fill": "Uncomplete",
      "rollback_not_sent": "Waiting"
    },
    "hospital_select": "จำนวน รพ.สมาชิกที่เลือกตัวชี้วัด",
    "hospital_send": "จำนวน รพ.สมาชิกที่ส่งตัวชี้วัด",
    "info": "Indicator Information",
    "input_type": "Input Type",
    "interpret_method": "Method of Result Interpretation",
    "label": "Symbol of Indicator",
    "less": "less",
    "less_is_better": "Lower Value = Good Quality",
    "less_than": "less than",
    "level_1": "level 1",
    "level_2": "level 2",
    "level_3": "level 3",
    "level_4": "level 4",
    "level_5": "level 5",
    "list": "รายการตัวชี้วัด",
    "main_group": "Main group",
    "meaning": "Definition / Description / Meaning of Indicator (English)",
    "meaning_label": "Definition / Description / Meaning of Indicator",
    "meaning_th": "Definition / Description / Meaning of Indicator (Thailand)",
    "median_to_percentile75": "Median : %{median} To Percentile75 : %{percentile75}",
    "min_to_percentile25": "Min : %{min} To Percentile25 : %{percentile25}",
    "more_is_better": "Higher Value = Good Quality",
    "more_than": "more than",
    "name": "Indicator Name (English)",
    "name_en": "Indicator Name (English)",
    "name_th": "Indicator Name  (Thailand)",
    "no_data": "ไม่มีข้อมูลตัวชี้วัด",
    "not_found": "ไม่พบตัวชี้วัด",
    "not_found_save_data": "ไม่มีข้อมูลการบันทึกตัวชี้วัด",
    "not_less_than": "not less than",
    "not_more_than": "not more than",
    "not_over": "not over",
    "note": "Note",
    "numerator": "ตัวตั้ง",
    "numerator_detail": "Standing Number Detail",
    "objective": "Objectives of Indicators",
    "other_detail": "Other Detail",
    "percentile25_to_median": "Percentile25 : %{percentile25} To Median : %{median}",
    "percentile75_to_max": "Percentile75 : %{percentile75} To Max : %{max}",
    "peroid": "Frequency of Data preparation",
    "please_select": "Please Select",
    "record_method": "Required Information",
    "ref_code": "Ref Code",
    "reject_reason": "Reject Reason",
    "relate": {
      "disable_reason": "บันทึกเหตุผลการยกเลิกเชื่อมโยงตัวชี้วัด",
      "title": "เชื่อมโยงตัวชี้วัด"
    },
    "rollback": {
      "reason": "เหตุผลการขอปลดล็อค",
      "reason_1": "เหตุผล 1 : รวบรวมข้อมูลได้ไม่ทันตามรอบระยะเวลU",
      "reason_2": "เหตุผล 2 : กรอกข้อมูลครบแล้วแต่ยังไม่ได้กดส่งเข้าสู่ระบบ",
      "reason_3": "เหตุผล 3 : ขอเพิ่มเติมข้อมูล เพราะข้อมูลที่ส่งไปแล้วไม่ครบถ้วน",
      "reason_4": "เหตุผล 4 : ขอแก้ไขข้อมูล เพราะข้อมูลที่ส่งไปแล้วมีความผิดพลาด",
      "reason_5": "เหตุผล 5 : มีปัญหาด้านการดำเนินงาน หรือทีมทำงาน ภายในโรงพยาบาล",
      "reason_6": "เหตุผล 6 : อื่นๆ"
    },
    "save_successful": "บันทึกเข้าระบบแล้ว",
    "search": "Search",
    "select_percentage": "อัตราของการเลือกตัวชี้วัด",
    "send_percentage": "อัตราของการส่งตัวชี้วัด",
    "sheet": {
      "can_not_import_abort_kpi": "ไม่สามารถนำเข้าข้อมูลของตัวชี้วัดที่ถูกยกเลิก",
      "export_template": "ดาวน์โหลดไฟล์ต้นแบบ",
      "import_data": "นำเข้าข้อมูล",
      "must_be_number": "ต้องเป็นจำนวน",
      "must_be_number_alt": "ต้องเป็นจำนวน ค่ะ",
      "no_data": "ไม่มีข้อมูลตัวชี้วัดในขณะนี้",
      "no_wait_for_save_data": "ไม่มีตัวชี้วัดที่รอการบันทึกในขณะนี้",
      "select_file": "เลือกไฟล์",
      "upload": "อัพโหลด",
      "warning": "แจ้งเตือน"
    },
    "source": "Source/Reference",
    "special_group": "Special group",
    "summary": {
      "no_data": "ไม่มีข้อมูลตัวชี้วัดในขณะนี้"
    },
    "title": "Indicator",
    "type": "Type of Indicator",
    "unit": "Unit of Measurement",
    "unlock_date": "Unlock date",
    "unlock_month": "Unlock Month",
    "unlock_reason": "Unlock Reason",
    "updated_at": "Date/Month/Year of the Last Revision",
    "updated_notice": "แก้ไขตัวชี้วัดสำเร็จ",
    "updated_reason": "Reasons for Revision"
  },
  "kpi_history_tab": {
    "all": "Total",
    "filled": "Complete",
    "not_fill": "Uncomplete",
    "overview": "Overview KPI",
    "title": "History KPI"
  },
  "kpi_sheet": {
    "analysis": "ขณะนี้เป็นช่วงเวลาสรุปส่งข้อมูลตัวชี้วัดเดือน",
    "analysis_month": "ขณะนี้เป็นช่วงเวลาวิเคราะห์ข้อมูลตัวชี้วัดของรอบข้อมูลเดือน %{month}",
    "completed": "สถานพยาบาลของคุณได้ส่งข้อมูลตัวชี้วัดครบถ้วนแล้ว",
    "not_yet": "สถานพยาบาลของคุณยังไม่ถึงช่วงส่งข้อมูลตัวชี้วัด",
    "send_failed_notice": "ส่งข้อมูลไม่สำเร็จ",
    "sended_notice": "ส่งข้อมูลสำเร็จ",
    "upload_failed_notice": "อัพโหลดข้อมูลไม่สำเร็จ",
    "uploaded_notice": "อัพโหลดข้อมูลสำเร็จ"
  },
  "kpi_sheets_size": {
    "recorder": "คุณมีข้อมูลตัวชี้วัดรอการบันทึก %{size} รอบการบันทึกข้อมูล",
    "sender": "คุณมีข้อมูลตัวชี้วัดรอการส่ง %{size} รอบการส่งข้อมูล"
  },
  "mailer": {
    "7_days": "7 วัน",
    "admin": "ผู้ดูแลโครงการ THIP",
    "already": "เรียบร้อยแล้ว",
    "because": "เนื่องจาก",
    "before_12am": "ก่อน 23:50น.",
    "before_due_date": "ก่อนวันที่สิ้นสุดการส่งข้อมูล",
    "before_trial_expiry": "บัญชีสมาชิกทดลองใช้ระบบ THIP ของสถานพยาบาล %{hospital_name} กำลังจะหมดอายุในวันที่ %{expiry_date}",
    "by": "โดยคุณ",
    "by_alt": "ทาง คุณ",
    "cannot": "ให้ได้",
    "data_for_month": "ข้อมูลของเดือน",
    "due_date": "โดยสิ้นสุดการกรอกและส่งข้อมูลภายใน",
    "end": "จึงเรียนมาเพื่อทราบ",
    "from": "จาก",
    "give_to": "ให้เเก่",
    "ha_cannot_rollback": "ทาง สรพ ไม่สามารถปลดล็อคข้อมูลของเดือน",
    "ha_rollback": "ทาง สรพ ได้ปลดล็อคข้อมูลของเดือน",
    "ha_rollback_notify": "ทาง สรพ จะแจ้งให้ทราบว่าการขอปลดล็อคข้อมูลของเดือน",
    "have_question": "หากคุณมีข้อสงสัยเกี่ยวกับโครงการ THIP โปรดติดต่อ",
    "last_day_of_rollback": "วันสุดท้ายของการขอปลดล็อค ของเดือน",
    "last_day_of_submission": "วันสุดท้ายของรอบการส่งข้อมูลของเดือน",
    "new_feedback": "มีการส่ง ปัญหา/ข้อเสนอแนะ",
    "new_registration": "มีการยื่นขอ ลงทะเบียนเป็นสมาชิกโครงการ THIP",
    "new_rollback_for_month": "มีการแจ้งขอปลดล็อคข้อมูลของเดือน",
    "password_en": "Password",
    "persuade_before_trial_expiry": "หากสมาชิกทดลองใช้ มีความต้องการใช้งานระบบ THIP กรุณาลงทะเบียนใช้งานจริง ก่อนวันที่ %{expiry_date}",
    "please_completed_before": "โปรดดำเนินการ ให้แล้วเสร็จภายใน",
    "please_fill_and_send_before": "กรุณากรอกและส่งข้อมูล ให้แล้วเสร็จภายใน",
    "remaining": "มีเวลาอีก",
    "rollback_month": "ได้ปลดล็อคข้อมูลเดือน",
    "time_up": "หมดเวลาเป็นที่เรียบร้อย",
    "to_admin": "เรียน ผู้ดูแลระบบ",
    "to_hospital_member": "เรียน รพ.สมาชิกระบบ THIP",
    "to_trial_user": "เรียน สมาชิกทดลองใช้ระบบ THIP",
    "to_user": "เรียน สมาชิกระบบ THIP",
    "to_user_with_name": "เรียน คุณ%{name} จากสถานพยาบาล %{hospital_name}",
    "url": "URL",
    "user_info": "คุณสามารถเข้าใช้งานระบบได้แล้วในขณะนี้ โดยใช้ข้อมูลต่อไปนี้ในการ login เข้าสู่ระบบ",
    "user_info_alt": "คุณสามารถเข้าใช้งานระบบ โดยใช้ Username และ Password ใหม่ ดังนี้",
    "username_en": "Username",
    "welcome_to_thip": "ยินดีต้อนรับเข้าสู่ระบบ Thailand Hospital Indicator Program",
    "welcome_to_thip_alt": "เข้าสู่ระบบ Thailand Hospital Indicator Program",
    "welcome_user": "ยินดีต้อนรับ คุณ%{name} จากสถานพยาบาล %{hospital_name}"
  },
  "member_tab": {
    "all": "All",
    "banned": "สมาชิกที่ถูกระงับการใช้งาน",
    "not_approved": "สมาชิกที่ยังไม่ได้อนุมัติการลงทะเบียน",
    "payment_status": "Payment Status",
    "trial": "Trial Member"
  },
  "not_member": "ท่านยังไม่ได้เป็นสมาชิกของปีงบประมาณปัจจุบัน",
  "number": {
    "currency": {
      "format": {
        "delimiter": ",",
        "format": "%u%n",
        "precision": 2,
        "separator": ".",
        "significant": false,
        "strip_insignificant_zeros": false,
        "unit": "$"
      }
    },
    "format": {
      "delimiter": ",",
      "precision": 3,
      "round_mode": "default",
      "separator": ".",
      "significant": false,
      "strip_insignificant_zeros": false
    },
    "human": {
      "decimal_units": {
        "format": "%n %u",
        "units": {
          "billion": "Billion",
          "million": "Million",
          "quadrillion": "Quadrillion",
          "thousand": "Thousand",
          "trillion": "Trillion",
          "unit": ""
        }
      },
      "format": {
        "delimiter": "",
        "precision": 3,
        "significant": true,
        "strip_insignificant_zeros": true
      },
      "storage_units": {
        "format": "%n %u",
        "units": {
          "byte": {
            "one": "Byte",
            "other": "Bytes"
          },
          "eb": "EB",
          "gb": "GB",
          "kb": "KB",
          "mb": "MB",
          "pb": "PB",
          "tb": "TB"
        }
      }
    },
    "nth": {
    },
    "percentage": {
      "format": {
        "delimiter": "",
        "format": "%n%"
      }
    },
    "precision": {
      "format": {
        "delimiter": ""
      }
    }
  },
  "post": {
    "add_instruction": "Click the button to add new post",
    "attention": "Attention!!!",
    "cover_recommend_size": "Recommended image size is 1280 x 720",
    "created_notice": "Post was created",
    "destroyed_notice": "Post was deleted",
    "edit": "Edit",
    "info": "Info",
    "new": "New",
    "no_data": "No Post",
    "read_all": "Read more",
    "title": "Post",
    "updated_notice": "Post was edited"
  },
  "recorder_kpi_sheets_completed": "คุณไม่มีข้อมูลตัวชี้วัดค้างกรอก",
  "recorder_kpi_sheets_not_yet": "ยังไม่ถึงรอบของการกรอกข้อมูลตัวชี้วัด",
  "registered_hospital": {
    "created_notice": "เพิ่มสมาชิกสำเร็จ",
    "destroyed_notice": "ลบสมาชิกสำเร็จ",
    "updated_notice": "แก้ไขสมาชิกสำเร็จ"
  },
  "registration": {
    "agreement_1": "มีความมุ่งมั่นที่จะใช้ข้อมูลจากระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP) เพื่อการพัฒนาคุณภาพของสถานพยาบาล",
    "agreement_2": "รับรองว่าข้อมูลที่ได้จัดส่งเข้าสู่ระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP) เป็นข้อมูลที่โรงพยาบาลได้ดำเนินการตรวจสอบข้อมูลและทำให้มั่นใจในข้อมูลสารสนเทศว่ามีความแม่นยำ ถูกต้อง คงสภาพ เชื่อถือได้และเป็นปัจจุบัน",
    "agreement_3": "ยินยอมให้สถาบันรับรองคุณภาพสถานพยาบาล (องค์การมหาชน) นำข้อมูลสารสนเทศภาพรวมที่ได้จากระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP) ไปใช้ประโยชน์เพื่อเป็นข้อมูลอ้างอิงทางวิชาการและส่งเสริมการพัฒนาคุณภาพ โดยไม่ระบุชื่อหรือโรงพยาบาลที่เป็นแหล่งที่มา",
    "agreement_4": "ยินยอมชำระค่าบำรุงการเข้าร่วมโครงการพัฒนาระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล (THIP) ตามประกาศของสถาบัน",
    "agreement_5": "รับรองว่าข้อมูลตัวชี้วัดที่เลือก จะดำเนินการส่งตามระยะเวลา และรายละเอียดข้อมูลกำหนดอย่างครบถ้วน รายการตัวชี้วัดที่โรงพยาบาลต้องการเปรียบเทียบวัดระดับคุณภาพโรงพยาบาล จำนวน %{kpi_count} ตัว",
    "attention": "Attention",
    "budget_year": "ลงทะเบียน/ต่ออายุสมาชิก ประจำปีงบประมาณ %{budget_year}",
    "consent": "ผู้ให้ความยินยอม",
    "currently_setting_on": "มีการตั้งค่าเปิดการลงทะเบียนในปีงบประมาณอื่นอยู่ในขณะนี้ คุณสามารถเปิดให้ลงทะเบียนได้ครั้งละ 1 ปีงบประมาณเท่านั้น",
    "date": "วันที่",
    "description": "หนังสือฉบับนี้ %{hospital_name} โดย %{director_name} ตำแหน่งผู้บริหารสูงสุด ซึ่งเป็นผู้มีอำนาจลงนามผูกพันกับสถาบัน รับรองคุณภาพสถานพยาบาล (องค์การมหาชน) เพื่อแสดงว่า %{hospital_name} สมัครใจและยินยอมปฏิบัติตามเงื่อนไขสำหรับเข้าร่วมโครงการระบบสารสนเทศเปรียบเทียบวัดระดับคุณภาพ โรงพยาบาล (THIP) ดังนี้ ",
    "destroyed_member_notice": "ลบสมาชิก สำเร็จ",
    "hospital_seal": "ประทับตราสถานพยาบาล",
    "label": "Registration",
    "register_successful_description_1": "กรุณารอการอนุมัติจากผู้ดูแลระบบ",
    "register_successful_description_2": "ระบบจัดส่งรายละเอียดการลงทะเบียนไปยัง Email ผู้ประสานงานโครงการ THIP",
    "renew": "Renew member",
    "renew_instruction": "หากท่านต้องการเพิ่มผู้ใช้งานใหม่ ท่านสามารถเพิ่มโดยการเปลี่ยนข้อมูลจากผู้ใช้งานเก่าให้เป็นผู้ใช้งานใหม่ ระบบจะดำเนินการส่งรหัสผ่านไปยังอีเมลที่ท่านได้กำหนด",
    "renew_success": "ต่ออายุสมาชิกเสร็จสิ้น",
    "sign": "ลงชื่อ",
    "title": "ใบแจ้งความจำนงเข้าร่วมโครงการ",
    "title_with_app_name": "ใบแจ้งความจำนงเข้าร่วมโครงการ THIP",
    "trial_label": "Trial registration",
    "upgrade_membership_success": "ลงทะเบียนใช้งานจริงเสร็จสิ้น"
  },
  "reply": {
    "new": "Reply",
    "title": "Reply"
  },
  "rollback": {
    "approve": "อนุมัติ",
    "approve_notice": "การอนุมัติสำเร็จ",
    "approve_over_day_notice": "การอนุมัติไม่สำเร็จ รอบการของปลดล็อคเกินกำหนดของการออกรายงาน",
    "approve_request": "ยืนยันการปลดล็อคตามการยื่นคำร้อง",
    "by_hospital": "การปลดล็อคเฉพาะรายโรงพยาบาล",
    "hospital": "สถานพยาบาล",
    "hospital_info": "สถานพยาบาล: %{hospital}",
    "info": "รายละเอียดขอปลดล็อค",
    "no_data": "ไม่มีข้อมูลที่สามารถปลดล็อคระบบได้",
    "please_select_month": "กรุณาเลือก เดือนที่ต้องการ ปลดล็อค",
    "reason": "เหตุผลการขอปลดล็อค",
    "reason_info": "เหตุผลการขอปลดล็อค: %{reason}",
    "reject": "ปฏิเสธ",
    "reject_reason": "เหตุผลการปฏิเสธคำร้อง",
    "reject_request": "ปฏิเสธการปลดล็อคตามการยื่นคำร้อง",
    "request": "ขอปลดล็อคระบบเพื่อแก้ไขข้อมูล",
    "requester": "ผู้ขอปลดล็อค",
    "round": "รอบการขอปลดล็อค",
    "round_info": "รอบการขอปลดล็อค: %{round}",
    "save_reject_reason": "บันทึกเหตุผลการปฏิเสธคำร้อง",
    "unlock_notice": "การปลดล็อคสำเร็จ"
  },
  "rollback_tab": {
    "by_all": "การปลดล็อคทั้งระบบ",
    "by_hospital": "การปลดล็อคเฉพาะรายโรงพยาบาล",
    "by_request": "การปลดล็อคตามการยื่นคำร้อง"
  },
  "screened_out": {
    "approved": "อนุมัติ",
    "budget_year": "ปีงบประมาณ",
    "created_notice": "เพิ่มรายการยกเลิกตัวชี้วัดสำเร็จ",
    "destroyed_notice": "ลบรายการยกเลิกตัวชี้วัดสำเร็จ",
    "detail": "เพิ่มยกเลิกตัวชี้วัด",
    "hospital": {
      "code": "รหัสสถานพยาบาล",
      "name": "ชื่อสถานพยาบาล",
      "title": "สถานพยาบาล"
    },
    "kpi": {
      "code": "รหัสตัวชี้วัด",
      "name": "ชื่อตัวชี้วัด",
      "title": "ตัวชี้วัด"
    },
    "name": "ยกเลิกตัวชี้วัด",
    "no_data": "No KPI",
    "start_month": "เดือนที่เริ่มมีผล",
    "updated_notice": "แก้ไขรายการยกเลิกตัวชี้วัดสำเร็จ"
  },
  "sender_kpi_sheets_completed": "คุณไม่มีข้อมูลตัวชี้วัดค้างส่ง",
  "sender_kpi_sheets_not_yet": "ยังไม่ถึงรอบของการส่งข้อมูลตัวชี้วัด",
  "session": {
    "feature_trial": "เปิดทดลองใช้งาน",
    "forgot_password": "Forgot password",
    "sign_in": "Login",
    "sign_in_to_app": "Login to",
    "sign_up": "Register",
    "trial_sign_up": "Trial"
  },
  "sidebar_menu": {
    "about_hospital": "Member",
    "analysis": "Analysis",
    "change_password": "Change Password",
    "feedback": "Helpdesk",
    "home": "Index",
    "hospital": "Hospital",
    "kpi": "KPI",
    "kpi_audit": "KPI Audit",
    "kpi_history": "History",
    "kpi_screened_out": "Cancel KPI",
    "member": "Member",
    "post": "Post",
    "renew_member": "Renew Membership",
    "send_data_to_erp": "Send Data To ERP",
    "settings": "Settings",
    "unlock": "Unlock",
    "upgrade_membership": "Registration"
  },
  "special_group": {
    "created_notice": "Special group created successfully",
    "destroyed_notice": "Special group deleted successfully",
    "updated_kpi_notice": "KPI updated successfully",
    "updated_notice": "Special group updated successfully"
  },
  "support": {
    "array": {
      "last_word_connector": ", and ",
      "two_words_connector": " and ",
      "words_connector": ", "
    }
  },
  "time": {
    "am": "am",
    "formats": {
      "long": "%B %d, %Y %H:%M",
      "short": "%d %b %H:%M"
    },
    "pm": "pm"
  },
  "time_with_zone": {
    "formats": {
    }
  },
  "user": {
    "actived": "Usage status",
    "add": "Add",
    "add_instruction": "Click the button to add new user",
    "change_password": "Change Password",
    "confirm_new_password": "Confirm Password",
    "created_notice": "User created successfully",
    "delete_confirmation": "Are you sure you want to delete %{user}",
    "destroyed_notice": "User deleted successfully",
    "edit": "Edit",
    "email": "Email",
    "first_name": "First Name",
    "first_name_en": "First Name (EN)",
    "full_name": "Full Name",
    "full_name_en": "Full Name (EN)",
    "hospital": "Hospital",
    "info": "User Information",
    "last_name": "Last Name",
    "last_name_en": "Last Name (EN)",
    "new_password": "New Password",
    "no_data": "No data",
    "old_password": "Old Password",
    "phone_number": "Phone Number",
    "random_password": "Random Password",
    "type": "Type",
    "updated_notice": "User updated successfully"
  },
  "views": {
    "pagination": {
      "first": "&laquo; First",
      "last": "Last &raquo;",
      "next": "Next &rsaquo;",
      "previous": "&lsaquo; Prev",
      "truncate": "&hellip;"
    }
  }
});
